@import "~@ivaoaero/front-components/dist/variables.scss";

.atis {
  padding-top: 20px;
  margin-left: -15px !important;
  margin-right: -15px !important;

  &_accordion {
    border-radius: 130px;
  }

  &_card {
    border: none !important;

    &_first {
      border-top-left-radius: 13px !important;
      border-top-right-radius: 13px !important;
    }

    &_last {
      border-bottom-left-radius: 13px !important;
      border-bottom-right-radius: 13px !important;
    }

    &_header {
      background-color: $lightBlue !important;

      button {
        color: #f8f8f8 !important;
        width: 100%;
      }
    }
  }
}

[data-theme="dark"] {
  .atis {
    &_card {
      background: $backgroundColorDarkSecondary !important;
      color: white !important;
    }
  }
}
