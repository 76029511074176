@import "~@ivaoaero/front-components/dist/variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-daterangepicker/daterangepicker.css";
@import "~@ivaoaero/front-components/dist/index.scss";
@import "~@ivaoaero/front-components/dist/components";

.ol-popup {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  color: black !important;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 5px;
  border-radius: 10px;
  bottom: 2px;
  left: -50px;
  min-width: 250px;
  z-index: 10000;
  text-align: center;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
