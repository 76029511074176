@import "~@ivaoaero/front-components/dist/variables.scss";

.pilot_subheader {
  &_section {
    @media (width >= 768px) {
      &_left {
        padding-left: 0;
        padding-right: 15px;
        //margin-left: -15px;
      }

      &_right {
        padding-left: 7px;
        padding-right: 15px;
      }
    }

    @media (width < 768px) {
      //margin-left: -15px !important;
      margin-right: -15px !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &_fp {
    background-color: white !important;
    padding: 15px;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &_fp::-webkit-scrollbar {
    display: none;
  }
}


[data-theme="dark"] {
  .pilot_subheader {
    &_fp {
      background-color: $backgroundColorDarkSecondary !important;
      color: white !important;
    }
  }
}
