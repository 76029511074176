@import "~@ivaoaero/front-components/dist/variables.scss";

.main_search_bar {
  border-radius: 13px !important;

  &_button {
    background-color: $primary !important;
    height: 40px;
  }

  &_input {
    background: none;

    input {
      background: $grey;
    }

    input:disabled {
      background: $grey;
    }

    input:focus {
      background: $grey;
    }

    label {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &_select {
    &_select > div {
      background-color: $grey !important;
      color: black !important;
    }
  }
}

.rounded {
  border-radius: 13px !important;
}


[data-theme="dark"] {
  .main_search_bar {
    background-color: $backgroundColorDarkSecondary !important;
    color: white !important;

    &_input {
      background: none;

      input {
        background: $greyDarkMode !important;
        border: none !important;
        color: white !important;
      }

      input:disabled {
        background: $greyDarkMode !important;
      }

      input:focus {
        background: $greyDarkMode !important;
      }

      div > div > button {
        background: $greyDarkMode !important;
        color: white;
      }
    }

    &_select {
      color: white !important;

      &_select > div {
        background-color: $greyDarkMode !important;
        color: white !important;
        border: none !important;

        div > div > svg, div {
          color: white !important;
        }
      }
    }
  }
}
