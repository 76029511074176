.badge-success-degraded {
  background-color: #A7E4BC !important;
  color: black;
  margin-right: -15px;
  margin-bottom: 5px;
  font-size: 100%;
  padding: 0 !important;

  a {
    vertical-align: middle;
    display: inline-block;
  }
}

.badge-success:hover {
  background-color: #8EC2A0 !important;
}
