@import "~@ivaoaero/front-components/dist/variables.scss";

.main_subheader_section {
  &_logo {
    position: absolute;
    right: 0;
    fill: rgb(255, 255, 255, 10%);
  }
}

.background_card_logo {
  width: 100%;
  justify-content: right !important;
}
