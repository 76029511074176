@import "~@ivaoaero/front-components/dist/variables.scss";

.pilot_subheader_section {
  @media (width >= 768px) {
    &_center {
      padding-left: 7.5px;
      padding-right: 15px;
    }
  }

  @media (width < 768px) {
    //margin-left: -15px !important;
    margin-right: -15px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

}
