@import "~@ivaoaero/front-components/dist/variables.scss";

.settings {
  &_card {
    height: 150px !important;
    border-radius: 13px !important;
    border: none;

    &_body {
      padding-bottom: 0px !important;
      padding-top: 40px !important;
    }
  }
}


[data-theme="dark"] {
  .settings {
    &_card {
      color: white !important;
      background: $backgroundColorDarkSecondary !important;
    }
  }
}
